import axios from "axios";
import moment from 'moment';

class Sftp {
  constructor() {
      this.Sftp = axios.create({
      baseURL: process.env.REACT_APP_PUBLIC_DOMAIN_3,
      withCredentials: true,
    });
  }
  
  read = () => {
    console.log('Read sftp Service')
    return this.Sftp
    .get("read")
    .then(({data}) => data)
    // .then(({data}) => console.log(data))
  }
  create = (event) => {
    console.log(`Create service`)
    return this.Sftp
      .post("create", {event})
      .then(({ data }) => data);
  }
  readmongo = (event) => {
    const e = moment(event).format('YYYY-MM-DD')
    console.log('Reading ppto from mongo, date: ' + e)
    return this.Sftp
     .get(`read/day/${e}`)
    .then(({data}) => data)
    // .then(({data}) => console.log(data))
  }
}

const sftpService = new Sftp();

export default sftpService;