import axios from "axios";

class User {
  constructor() {
    this.User = axios.create({
      baseURL: process.env.REACT_APP_PUBLIC_DOMAIN_3,
      withCredentials: true
    });
  }

  toISODate = (t) =>{
    return ('0'+ t.getDate()).slice(-2) + '-' +
           ('0'+ (t.getMonth()+1)).slice(-2) + '-' +
           t.getFullYear(t)
  }
    
  create = (event) => {
    console.log(`Create service`)
    return this.User
      .post("create", {event}) 
      .then(({ data }) => data);
  }

  read = (event) => {
    console.log('Read Service')
    const e = this.toISODate(event)
    return this.User
      .get(`data/${e}`)
      .then(({ data }) => data);
      
  }

}

const userService = new User();

export default userService;


