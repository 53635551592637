import React, { Component } from 'react';
import { withAuth } from "../lib/AuthProvider";
import Navbar from "../components/Navbar";
import LoadingDots  from "../components/LoadingDots";
import sftp from '../lib/sftp-service';
import user from '../lib/user-service';
import { Button } from 'react-bootstrap';
import Cal from "../components/date";



class Dataread extends Component {
    state = {
        data: {
            data: {
                id:'',
                name: '',
                start: '',
            },
        },
        kpi: [{Objetivo:'',Real:'',Diferencia:'',Fecha:'', KPI: '', ObjetivoAcu:'', RealAcu:'', DiferenciaAcu:'', _id: 0},{_id: 1},{_id: 2},{_id: 3},{_id: 4},{_id: 5},{_id: 6},{_id: 7},{_id: 8},{_id: 9},{_id: 10},{_id: 11}],
        // kpi: [{Objetivo:'',Real:'',Diferencia:'',Fecha:'', KPI: '', ObjetivoAcu:'', RealAcu:'', DiferenciaAcu:''},'','','','','','','','','','','','',''],
        id: [{_id: 0},{_id: 1},{_id: 2},{_id: 3},{_id: 4},{_id: 5},{_id: 6},{_id: 7},{_id: 8},{_id: 9},{_id: 10},{_id: 11}],
        isLoading: true,
        status: "loading",
        timer: '',
    }

    componentDidMount() {
        // setTimeout(() => {
        //     this.handlesftp()
        //     }, 1000)
        this.setState({
            timer: setInterval(() => {
                this.handlesftp()
            }, 10800000)
        })
    }
      
    componentWillUnmount() {
    clearTimeout(this.state.timer);
    }
   
    handlesftp = () => {
        console.log('update')
        this.setState({
            isLoading: false
        });
        sftp.read ()
        .then((kpi) => {
            this.setState({
                kpi,
                isLoading: true
            })
        })
        .then(() => {
            const kpi = this.state.kpi
            user.create (kpi)
            this.setState({
                kpi,
                status: "loaded",
                isLoading: true,
            });
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
            });
        });
    };

    handledb = () => {
        this.setState({
            isLoading: false
        });
        user.read ()
        .then((kpi) => {
            this.setState({
                kpi,
                status: "loaded",
                isLoading: true,
            });
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
            });
        });
    };

    tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
      
        if (dateStringCandidateValue === ''){
        } else {
            if (!dateStringCandidateValue) { return null; }
            let mapFormat = format
                    .split("")
                    .reduce(function (a, b, i) { a[b] = i; return a;}, {});
            const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
            const datePart = dateStr2Array.slice(0, 3);
            let datePartFormatted = [
                    +datePart[mapFormat.y],
                    +datePart[mapFormat.m]-1,
                    +datePart[mapFormat.d] ];
            if (dateStr2Array.length > 3) {
                dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
            }
            // test date validity according to given [format]
            const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
            return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
                dateTrial.getMonth() === datePartFormatted[1] &&
                dateTrial.getDate() === datePartFormatted[2]
                    ? dateTrial :
                    null;
        }
    }
 
    logChildValue = (value) => {
        this.setState({kpi: value});
    }
    
    render () {
        const { logout } = this.props;
        const {Fecha} = this.state.kpi[0]
        const date =  this.tryParseDateFromString(Fecha, 'dmy')
        

        return (
        <div className="App">
            <Navbar/>
        <header className="App-header">
            <Cal props={date} parentTextBoxValue={this.state.parentTextBoxValue} onSubmitChild={this.logChildValue} />
            <table>
                <thead style={{margin: '5% 0 0 0'}}>
                    <tr>
                        <th className="desc">
                            <h3>DESCRIPCIÓN</h3>
                        </th>
                        <th className="title">
                            <h3>DIARIO</h3>
                            <td>    
                                <tr>OBJETIVO</tr>
                                <tr>REAL</tr>
                                <tr>DIFERENCIA</tr>
                            </td>
                        </th>
                        <th className="titlemov">
                            <h3>ACUMULADO</h3>
                            <td>        
                                <tr>OBJETIVO</tr>
                                <tr>REAL</tr>
                                <tr>DIFERENCIA</tr>
                            </td>
                        </th>
                    </tr>
                </thead>
                {this.state.kpi.map(kpi => {
                    return  <tbody key={kpi._id}>
                                <tr className='trdesk'>
                                    <td className="tdprimarydesk">
                                        <p>{kpi.KPI}</p>
                                    </td>
                                    <td className="tdprimarymov">
                                        <p>DIARIO</p>
                                        <p>ACUM.</p>
                                    </td>
                                    <td className="trContainerBorder">
                                        <td>
                                            <h4>{kpi.Objetivo}</h4>
                                            <h4>{kpi.Real}</h4>
                                            <h4>{kpi.Diferencia}</h4>
                                        </td>
                                        <td>
                                            <h4>{kpi.ObjetivoAcu}</h4>
                                            <h4>{kpi.RealAcu}</h4>
                                            <h4>{kpi.DiferenciaAcu}</h4>
                                        </td>
                                    </td>
                                </tr>
                            </tbody>
                })} 
            </table>
            {this.state.isLoading === false ?
                <LoadingDots/>               
                :
                <div>
                    <Button variant="info" className='butn' onClick={() => this.handlesftp()}>READ FROM SFTP</Button>
                    <button className="logout" onClick={logout}><img src="../Images/18404466911582779196-128.png" alt="log" style={{width: '2.5rem'}}/></button>
                    {/* <Button variant="primary" className='butn' onClick={() => this.handledb()}>READ FROM DB</Button> */}
                </div>
            }
        </header>
        </div>
        )
    };
}



export default withAuth(Dataread);