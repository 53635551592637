import React, { Component } from "react";
import DatePicker, { registerLocale }  from "react-datepicker";
import LoadingDots  from "./LoadingDots";
import sales from '../lib/sales-service';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import es from 'date-fns/locale/es';

registerLocale('es', es)


class Cal extends Component {
    constructor(props) {
        
        super(props);
        this.state = {
            date: '',
            isLoading: true,
            kpiold: ''
        }
    }

    submitForm = () => {
       this.props.onSubmitChild(this.state.kpiold);
    }


    //Conversor String to Date
    tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
      
        if (!dateStringCandidateValue) { return null; }
        let mapFormat = format
                .split("")
                .reduce(function (a, b, i) { a[b] = i; return a;}, {});
        const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
        const datePart = dateStr2Array.slice(0, 3);
        let datePartFormatted = [
                +datePart[mapFormat.y],
                +datePart[mapFormat.m]-1,
                +datePart[mapFormat.d] ];
        if (dateStr2Array.length > 3) {
            dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
        }
        // test date validity according to given [format]
        const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
        return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
            dateTrial.getMonth() === datePartFormatted[1] &&
            dateTrial.getDate() === datePartFormatted[2]
                ? dateTrial :
                null;
    }
    //Guardar fecha seleccionada en calendario y extraer datos del día
    handleCalendarClose = (date) => {
        console.log(date)
        this.setState({
            date,
            isLoading: false
            })
            sales.onlyreadoldcal (date) 
            .then((kpiold) => {
                this.setState({
                    kpiold,
                    isLoading: true,
                })
           })
            .then (() => {
                //convert Date and add to kpi.FECHA
                let indexes = this.state.kpiold.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
                const FECHA =  moment(this.state.kpiold[indexes[0]].FECHA).format('YYYY-MM-DD')
                const {kpiold} = this.state;
                for (let i = 0; i < kpiold.length; i++) {
                    kpiold[i] = {
                        ...kpiold[i],
                        FECHA: FECHA
                        }
                };
                this.setState({
                    kpiold,
                    isLoading: true,
                    autoplay: true,
                });
            })
            .then(() => { //Establecer fecha recuperada de la base de datos
                const Fecha =  moment(this.state.kpiold[0].FECHA).format('DD-MM-YYYY')
                const date = this.tryParseDateFromString(Fecha,'dmy')
                this.setState({
                    date,
                })
                this.submitForm()
            })
            .catch(error => {
                this.setState({
                    status: "error",
                    isLoading: false
                })
            })
        }
    
    
    handleCalendarOpen = (date) => {
        this.setState({
            date,
            })    
    }
    
    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar las props):
        if (this.props.props !== prevProps.props) {
            this.setState({
                date: this.props.props,
            })   
        }
         
    }
   
    
    //Calendarios conectados
    static getDerivedStateFromProps(props, state) {
        if (props.calendarDate === true) {
            state.date = ''
        }
        
        return null
    
    }
    
    render() {
        
    const {date} = this.state

    const style = {
        margin: '0 1% 1% 1%',
        padding: '1%',
        /*border: '1px solid #5F2412',
        borderRadius: '15px',*/
        width: '50%',
        // height: '115px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
    return (
        <div style={style}>
            <h2>SEGUNDA FECHA</h2>
        {this.state.isLoading === false ?
           <LoadingDots color={'white'}/>               
           :
           <div className='calMov'>
           {/* <h3>SELECCIONA UNA FECHA A COMPARAR</h3> */}
           <DatePicker
                className="calendar"
                locale='es'
                dateFormat="dd/MM/yyyy"
                minDate={new Date("2022/01/01")}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                selected={date} //fecha a mostrar
                onChange={date =>  this.setState({
                    date,
                    })}
                onClick={date =>  this.setState({
                    date,
                    })}
                maxDate={new Date()} // Max fecha a escojer
                // onCalendarClose={() => this.handleCalendarClose(date)}
                onSelect={(date) => this.handleCalendarClose(date)}
                onCalendarOpen={() => this.handleCalendarOpen(date)}
                placeholderText="dd/mm/aaaa"
               
            />
            </div>
           }
        </div>
        );
    };
}
export default Cal;