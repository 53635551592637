import React, { Component } from 'react';
import LoadingDots  from "../components/LoadingDots";
import sales from '../lib/sales-service';
import { withAuth } from "../lib/AuthProvider";
import moment from 'moment';
import $ from 'jquery';
import sftp from '../lib/sftp-service';


class Dashboard extends Component {
    state = {
        data: {
            data: {
                id:'',
                name: '',
                start: '',
            },
        },
        kpi: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        // kpiold: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        
        kpiold: [{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
        
        kpiold0: [{CODIGO:'',DESCRIPCION:'',FECHA: 'sin datos',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        history: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        id: [{_id: 0},{_id: 1},{_id: 2},{_id: 3},{_id: 4},{_id: 5},{_id: 6},{_id: 7},{_id: 8},{_id: 9},{_id: 10},{_id: 11}],
        hour: '',
        hora: '00',
        min: '00',
        isLoading: true,
        status: "loading",
        timer: '',
        autoplay: false,
        realtime: '',
        calendarDate: true,
        ppto: [{CENTRO: '',COSTE: '', DAY: '', FECHA:'', MARGEN: '', MONTH: '', VENTAS:'', YEAR:''},],
        selectedOption: '',
        resStatus:'',
    }

    componentDidMount() {
        setTimeout(() => {
            let hora = moment().format('HH')
            let min = moment().format('mm')
            this.handledbComplete()
        }, 1000)
        this.setState({
            timer: setInterval(() => {
                // let hora = moment().format('HH')
                let min = moment().format('mm:ss')
                switch (min) {
                    case '00:20' :
                      console.log('00!');
                      // this.handleSales()
                        this.handledbComplete()
                        // this.setState({
                        // hora,
                        // min
                        // })
                      break;
                    case '10:20':
                      console.log('10!');
                      // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '20:20':
                      console.log('20!');
                      // this.handleSales()
                     this.handledbComplete()
                    //  this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '30:20':
                      console.log('30!');
                      // this.handleSales()
                     this.handledbComplete()
                    //  this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '40:20':
                    console.log('40!');
                    // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    // hora,
                    // min
                    // })
                    break;
                    case '50:20':
                    console.log('50!');
                    // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    // hora,
                    // min
                    // })
                    break;
                    default:
                    
                }
            }, 1000)
        })
    }
      
    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    convertArray = (kpi) => {
        kpi.sort((a,b) => {
            if (a.TIPO < b.TIPO){
                return -1;
            } return 1;
        })
       
        this.arraymove(kpi,0,75) //COMP
        // this.arraymove(kpi,76,0) //D001
        // this.arraymove(kpi,77,1) //D101
        this.arraymove(kpi,76,84) //E-COMMERCE
        this.arraymove(kpi,79,83) //I101
        this.arraymove(kpi,78,82) //I100
        this.arraymove(kpi,77,83) //I010
        this.arraymove(kpi,76,82) //I000

        let ind = kpi.map((e,i) => e.CODIGO === 'T210' ?  i: '').filter(String)
        
        this.arraymove(kpi,ind,62) //T210
        // this.arraymove(kpi,78,79) //NO COMP

        // this.arraymove(kpi,0,75) //COMP
        // this.arraymove(kpi,73,0) //D001
        // this.arraymove(kpi,74,1) //D101
        // this.arraymove(kpi,76,86) //E-COMMERCE
        // this.arraymove(kpi,79,84) //I101
        // this.arraymove(kpi,78,83) //I100
        // this.arraymove(kpi,77,82) //I010
        // this.arraymove(kpi,76,81) //I000
        // arraymove(kpi,74,76) //NO COMP
    }
   
    filterShop = (kpi) => { //ELIMINAR TIENDAS

    let indexes = kpi.map((e,i) =>  e.CODIGO === 'T005' 
                                || e.CODIGO === 'T027'
                                || e.CODIGO === 'I302'
                                || e.CODIGO === 'I001'
                                || e.CODIGO === 'T035'
                                || e.CODIGO === 'T041'
                                || e.CODIGO === 'I032'
                                || e.CODIGO === 'T999'
                                || e.CODIGO === 'F006'
                                || e.CODIGO === 'F004'
                                || e.CODIGO === 'F001' 
                                || e.CODIGO === 'T609' 
                                || e.CODIGO === 'T301'  ?  i: '').filter(String)
                            
    for(let i = indexes.length -1; i >= 0; i--){
        kpi.splice(indexes[i],1)
    }

    let indexes2  = kpi.map((e,i) =>  e.CODIGO === 'T409'
                                || e.CODIGO === 'T029'
                                || e.CODIGO === 'D001'
                                || e.CODIGO === 'D101'
                                || e.CODIGO === null ? i: '').filter(String)

    for(let i = indexes2.length -1; i >= 0; i--){
        kpi.splice(indexes2[i],1)
        }

    }

    unionEco = (kpi) => { //UNIR ECORGANIC
      
       let date = kpi[0].FECHA
    if (date > '2022-10-03') {
        console.log('mayor '+ kpi.length)
        // let i = kpi.map((e,i) => e.CODIGO === 'T401' ?  i: '').filter(String)
        // kpi[i].CODIGO = 'T601'
        // console.log(kpi[i])
        let oldIndexes = kpi.map((e,i) => e.CODIGO === 'T401' || e.CODIGO === 'T402' || e.CODIGO === 'T403' || e.CODIGO === 'T404' || e.CODIGO === 'T405'|| e.CODIGO === 'T406' || e.CODIGO === 'T407' || e.CODIGO === 'T408' || e.CODIGO === 'T409'|| e.CODIGO === 'T410' || e.CODIGO === 'T411' || e.CODIGO === 'T412'?  i: '').filter(String)
        for(let i = oldIndexes.length -1; i >= 0; i--){
            kpi.splice(oldIndexes[i],1)
        }
       } else {
        console.log('menor ' + kpi.length)

        let oldIndexes = kpi.map((e,i) => e.CODIGO === 'T601' || e.CODIGO === 'T602' || e.CODIGO === 'T603' || e.CODIGO === 'T604' || e.CODIGO === 'T605' || e.CODIGO === 'T606' ?  i: '').filter(String)
        for(let i = oldIndexes.length -1; i >= 0; i--){
            kpi.splice(oldIndexes[i],1)
        }
        let i = kpi.map((e,i) => e.CODIGO === 'T401' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T601'
         i = kpi.map((e,i) => e.CODIGO === 'T402' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T602'
         i = kpi.map((e,i) => e.CODIGO === 'T403' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T603'
         i = kpi.map((e,i) => e.CODIGO === 'T404' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T604'
         i = kpi.map((e,i) => e.CODIGO === 'T405' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T605'
         i = kpi.map((e,i) => e.CODIGO === 'T406' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T606'

        let oldIndexes2 = kpi.map((e,i) =>  e.CODIGO === 'T607' || e.CODIGO === 'T210'|| e.CODIGO === 'T609'|| e.CODIGO === 'T610' || e.CODIGO === 'T611' || e.CODIGO === 'T612' ?  i: '').filter(String)
        for(let o = oldIndexes2.length -1; o >= 0; o--){
            kpi.splice(oldIndexes2[o],1)
        }
        
        
        let o = kpi.map((e,i) => e.CODIGO === 'T407' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T607'

         o = kpi.map((e,i) => e.CODIGO === 'T408' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T210'

        // o = kpi.map((e,i) => e.CODIGO === 'T409' ?  i: '').filter(String)
        // kpi[o].CODIGO = 'T609'

        o = kpi.map((e,i) => e.CODIGO === 'T410' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T610'

        o = kpi.map((e,i) => e.CODIGO === 'T411' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T611'

        o = kpi.map((e,i) => e.CODIGO === 'T412' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T612'

        }

        

    }

    
    handleSales = () => {
        this.setState({
            isLoading: false,
        });
        sales.onlyread ()
        .then((kpi) => {
            //Change Array order and save state
            this.filterShop(kpi)
            this.unionEco(kpi)
            this.convertArray(kpi)
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then(() => {
            //convert Date and add to kpi.FECHA
            let indexes = this.state.kpi.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
            const FECHA =  moment(this.state.kpi[indexes[0]].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then(() => {
            const kpi = this.state.kpi
            sales.create (kpi)
            const dateHour = new Date()
            const hour = dateHour.getHours()
            this.setState({
                kpi,
                hour,
                status: "loaded",
                isLoading: true,
                autoplay: true,
            })
            this.handleOldSales(kpi[0].FECHA)
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
                });
            });
    }

    handleOldSales = (olDate) => {
        this.setState({
            isLoading: false,
        });
        // let olDate = this.state.kpi[0].FECHA
        sales.onlyreadold(olDate)
        .then((kpiold) => {
            if (kpiold.length > 1) {
                this.setState({
                    kpiold,
                    status: "loaded",
                    isLoading: true,
                })
            } else {
                this.setState({
                    kpiold: this.state.kpiold0,
                    status: "loaded",
                    isLoading: true,
                })
            }
        })
        .then (() => {
            if (this.state.kpiold[0].FECHA !== "sin datos" ) {
                //convert Date and add to kpi.FECHA
                let indexes = this.state.kpiold.map((e,i) => e.FECHA !== null ?  i: '').filter(String)
                const FECHA =  moment(this.state.kpiold[indexes[0]].FECHA).format('YYYY-MM-DD')
                const {kpiold} = this.state;
                for (let i = 0; i < kpiold.length; i++) {
                    kpiold[i] = {
                        ...kpiold[i],
                        FECHA: FECHA
                        }
                }
                this.setState({
                    kpiold,
                    isLoading: true,
                });
            }
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false,
            });
        });
    }

    handledbComplete = () => {
        const date =  moment().format('YYYY-MM-DD')
        ////Clean calendar
        {this.state.calendarDate === true ?
            this.setState({
                calendarDate: false
                })
            :
            this.setState({
                calendarDate: true
                })
        }
        ////////////////
        this.setState({
            isLoading: false
            })
        sales.read (date) 
        .then((kpi) => {
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then (() => {
            //convert Date and add to kpi.FECHA
            let indexes = this.state.kpi.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
            const FECHA =  moment(this.state.kpi[indexes[0]].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            });
        })
        .then(() => {
            const {kpi} = this.state
            const dateHour = new Date()
            const hour = dateHour.getHours()
            this.setState({
                hour,
                status: "loaded",
                isLoading: true,
                autoplay: true,
            })
            this.handleOldSales(kpi[0].FECHA)
        })
        .then(()=> {
            const {kpi} = this.state
            let hora = moment(kpi[0].updated_at).format("HH")
            let min =  moment(kpi[0].updated_at).format("mm")
            this.setState({
                hora,
                min
            })
            this.updateAlert()
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
    }
    
    handledb = () => {
        this.setState({
            isLoading: false
        });
        sales.read ()
        .then((kpi) => {
            this.setState({
                kpi,
                status: "loaded",
                isLoading: true,
            });
        })
        .then (() => {
            //convert Date and add to kpi.FECHA
            const FECHA =  moment(this.state.kpi[0].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            });
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
            });
        });
    };

    handlesftpComplete = () => {
        const date =  moment().format('YYYY-MM-DD')
        this.setState({
            isLoading: false
            })
        sftp.readmongo (date) 
        .then((ppto) => {
            this.setState({
                ppto,
                isLoading: true,
                autoplay: true,
            })
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
    }

    tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
      
        if (dateStringCandidateValue === ''){
        } else {
            if (!dateStringCandidateValue) { return null; }
            let mapFormat = format
                    .split("")
                    .reduce(function (a, b, i) { a[b] = i; return a;}, {});
            const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
            const datePart = dateStr2Array.slice(0, 3);
            let datePartFormatted = [
                    +datePart[mapFormat.y],
                    +datePart[mapFormat.m]-1,
                    +datePart[mapFormat.d] ];
            if (dateStr2Array.length > 3) {
                dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
            }
            // test date validity according to given [format]
            const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
            return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
                dateTrial.getMonth() === datePartFormatted[1] &&
                dateTrial.getDate() === datePartFormatted[2]
                    ? dateTrial :
                    null;
        }
    }
 
   
    arraymove = (arr, fromIndex, toIndex) => {
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    
    fun = (x) => {
        let ne = this.state.kpi.filter(function(val, i) {
            if (val.CODIGO !== 'COMP' && val.CODIGO !== 'NO COMP' && val.CODIGO !== 'FRANQ') {
                return val.TIPO===x;
                } 
            })
        return ne
    }
   
    toggle = () => {
        console.log('pulsado')
        var lab = $("label");
        var swi = $(".custom-switch");
        if (lab.css("color",'green!important')) {
            lab.css("color",'red!important')
            } else {
                lab.css("color",'green!important')
            }
    }

    formatAsPercentage = (num) => {
        return new Intl.NumberFormat('default', {
                style: 'percent',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(num / 100);
    }

    test = (event) => {
            // $('table').hide();
            // $("#searchBar").change(function() {
            const { name, value } = event.target;
            // this.setState({ [name]: value });   
            // $("#searchBar").on('input',function() {
              
              $('table').show();
              var selection = value.toUpperCase();
              var dataset = $('#myTable tbody').find('tr');
              // show all rows first
              dataset.show();
              // filter the rows that should be hidden
              dataset.filter(function(index, item) {
                return !$(item).find('th h3').text().includes(selection);
              }).hide();

              
            //   dataset.filter(function(index, item) {
            //     return $(item).find('th h3').text().split(',').indexOf(selection) === -1;
            //   }).hide();
            // });
            
         
    }
   
    updateAlert = () => {

        let {hora, min, resStatus} = this.state
        let horaNow = moment().format("HH")
        let minNow =  moment().format("mm")
        
        // console.log('hora: ' + hora)
        // console.log('min: ' + min)
        // console.log('hora actual: ' + horaNow)
        // console.log('min actual' + minNow)

        if (hora = horaNow && minNow > Number(min)+10 ) {
            // sales.email('retraso en actualización')
            resStatus = 'RETRASO EN LA ACTUALIZACIÓN'
            console.log('retraso en actualización')
            this.setState({
                resStatus,
            })
        } else {
            resStatus = 'ACTUALIZADO'
            console.log('onTime')
            this.setState({
                resStatus,
            })
        }
       

    }

    render () {
        const { logout } = this.props;
       
        const {hour} = this.state;
       
        const redHour = {
            color:'white',
            background:'orange',
            fontWeight: 'bold'
        }
        const greenHour = {
            color:'white',
            background:'#4CAF50',
            fontWeight: 'bold',
        }
        const redOld = {
            // color:'red',
            background:'#f0f3f5'
        }
        const greenOld = {
            // color:'#4CAF50',
            background:'#f0f3f5'
        }
        const green = {
            color:'#4CAF50'
        }
        const red = {
            color:'red'
        }
        const up = {
            background:'#f4de9c',
            borderColor:'#f3ce81'
        }
        const blue = {
            color:'white',
            background: '#2196F3',
            background: '#c95e2c',
            borderColor: '#2c8cf3',
            fontWeight: 'bold',
        }
        /*Nav scroll */
        $(function() {
            var header = $(".fixNav");
            $(window).scroll(function() {    
                var scroll = $(window).scrollTop();
            
                if (scroll >= 150) {
                    header.removeClass('fixNav').addClass("delNav");
                } else {
                    header.removeClass("delNav").addClass('fixNav');
                }
            });

            
        });
       
       

        return (

        <div className="AppPL">
            <div className='fixNav'>
            <div style={{background: '#c95f2c'}}>
            <button style={{background: '#c95f2c'}} className="logout" onClick={logout}><img src="../Images/18404466911582779196-128.png" alt="log" style={{width: '1rem'}}/></button>
            </div>
            <div className='hourBar'>
                <h1 className='hora'>HORA DE ACTUALIZACIÓN {this.state.hora}:{this.state.min}</h1>
            </div>
            </div>
        <header className="App-header-pl dash">
        {this.state.isLoading === false ?
                
                <div className="loadup" >
                <LoadingDots color={'white'}/>   
                </div>          
              :
              <div>
              <h1 className='tHead'>ESTADO: {this.state.resStatus} </h1>
              </div>
              }
          
        {this.state.isLoading === false ?
             <LoadingDots/>               
            :
            <div>
                {/* <Button variant="info" className='butn' onClick={() => this.handleSales()}>READ FROM CMZ4</Button>
                <Button variant="primary" className='butn' onClick={() => this.handledb()}>READ FROM MONGO</Button>
                <Button variant="secondary" className='butn' onClick={() => this.handleOldSales()}>READ OLD MONGO</Button>
                <Button variant="secondary" className='butn' onClick={() => this.handleSalesYear()}>Update DB</Button>
                <Button variant="secondary" className='butn' onClick={() => this.sendEmail()}>SendEmail</Button> */}
            </div>
            }
        </header>
        </div>
        )
    }
}

export default withAuth(Dashboard);