import { Switch, Route, BrowserRouter } from "react-router-dom";
import AuthProvider from "./lib/AuthProvider";
import PrivateRoute from "./components/PrivateRoute.js";
import AnonRoute from "./components/AnonRoute";
import Login from "./pages/Login";
import Signup from "./pages/SignUp";
// import Home from "./pages/Home";
// import Data from "./pages/Data";
// import Datacsv from "./pages/Datacsv";
import Dataread from "./pages/Dataread";
import Player from "./pages/Player";
import Player2 from "./pages/Player2";
import Dev from "./pages/Dev";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import Status from "./pages/Status";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import './sass.css';

function App() {
  
  return (
    <BrowserRouter>
      <AuthProvider>
          <Switch>
            <AnonRoute exact path="/" component={Login} />
            <AnonRoute exact path="/signup" component={Signup} />
            {/* <AnonRoute exact path="/player-4eb6916e-45c0-4f97-9b2c-7a4094d133a7" component={Player} /> */}
            {/* <AnonRoute exact path="/read" component={Dataread} /> */}
            <PrivateRoute exact path="/sales" component={Player2} />
            <AnonRoute exact path="/review87" component={Dev} />
            <AnonRoute exact path="/status" component={Status} />
            <PrivateRoute exact path="/dash" component={Dashboard} />
            {/* <PrivateRoute exact path="/private" component={Dataread} /> */}
            <Route path='*' exact={true} component={NotFound} />
          </Switch>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
