import { Form } from "react-bootstrap";
import React, { Component } from "react";
import { withAuth } from "../lib/AuthProvider";
// import { Button } from "bootstrap";
import { Button } from 'react-bootstrap';
import logo from '../images/logo-veriras.svg'

class LoginForm extends Component {
    constructor(...args) {
        super(...args);
        this.state = { 
            validated: false, 
            username: "",
            password: "",
            retry: false
        };
    };
     
    handleSubmit = event => {
        const form = event.currentTarget;
        const { username, password } = this.state;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            this.props.login({ username, password })
            this.setState({retry: this.props.retry})
        }
        this.setState({ 
            validated: true
        });
    }
    
    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });   
    };
    
    
    // componentWillUnmount() {
    //     const {retry} = this.state;
    //     if(retry === true) {
    //         setTimeout(() => {
    //             this.setState({
    //                 retry: false
    //             })
    //         }, 5000);
    //     }
    //     console.log(retry)
    // }
   
    render() {

        const { username, password, validated, retry } = this.state;
        const {isLoggedin} = this.props;
        const alert = {
            margin: '10% auto',
            textAlignLast: 'center',
            color: '#e47830',
            textAlign: 'center',
        }
        const logimg = {
            display: 'flex',
            margin: '5% auto 2% auto',
            width: '30%'
        }


        
        if(retry === true) {
            setTimeout(() => {
                this.setState({
                    retry: false
                })
            }, 5000);
        }

        
        
        return (
            <div className="logcontainer">
                <Form
                    noValidate
                    validated={validated}
                    onSubmit={e => this.handleSubmit(e)}
                    className='Form'
                >
                <img src={logo} className='logimg'style={logimg}></img>
                <Form.Group controlId="validationCustom01" className="sign">
                    <Form.Label style={{margin: "2rem 0 -2rem 0"}}>USUARIO:</Form.Label>
                    <Form.Control 
                        required
                        type="text"
                        name="username"
                        minLength="4"
                        value={username}
                        onChange={this.handleChange}
                        className='log'
                        style={{width: '15%',textAlign: 'center'}}     
                    />
                <div className="line"></div>
                    <Form.Control.Feedback type="invalid">
                    Usuario requerido mínimo 4 carácteres
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationCustom02" className="sign">
                    <Form.Label style={{margin: "2rem 0 0 0"}}>CONTRASEÑA:</Form.Label>
                    <Form.Control
                        required
                        type="password"
                        name="password"
                        minLength="4"
                        value={password}
                        onChange={this.handleChange}
                        className='log'
                        style={{width: '15%',textAlign: 'center'}}                 
                    />
                    <div className="line"></div>
                    <Form.Control.Feedback type="invalid">
                    Contraseña requerida mínimo 4 carácteres
                    </Form.Control.Feedback>
                    <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
                
                <div className="btn-signup">
                    {/* <input className='input'type="submit" value="LOGIN" /> */}
                    <Button variant="success" type="submit" >LOGIN</Button>
                </div>
                </Form>
                {isLoggedin === false && retry === true ?
                <h1 id='alert'style={alert}>USUARIO O CONTRASEÑA INCORRECTOS</h1>
                :
                <></>
                }
            </div>
        );
    }
}
  
export default withAuth(LoginForm);