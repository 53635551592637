import React, { Component } from "react";
import styled, { keyframes } from "styled-components";

const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
`;


const Dot = styled.div`
  background-color: black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 2px;
  /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${props => props.delay};
`;

class LoadingDots extends Component {
  render() {
    return (
      <DotWrapper className="loadMov" style={{margin: '0 auto'}}>
        <h1 className="load">CARGANDO</h1>
        <Dot style={{backgroundColor:`${this.props.color}`}} className='loadDot' delay="0s" />
        <Dot style={{backgroundColor:`${this.props.color}`}} className='loadDot' delay=".1s" />
        <Dot style={{backgroundColor:`${this.props.color}`}}className='loadDot' delay=".2s" />
      </DotWrapper>
    )
  }
}

export default LoadingDots;