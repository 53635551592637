import React, { Component } from "react";
import { Link } from 'react-router-dom';



class NotFound extends Component {
    render () {
        return (
        <div>
            <center>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h1 style={{fontSize:"1000%"}}>404</h1>
                <h2>PAGE NOT FOUND</h2>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h2><Link to="/">RETURN TO HOME PAGE</Link></h2>
            </center>
            {/* <img className="err" src='../404-error.png' alt='404'/> */}
        </div>
        );
    }
};

export default NotFound;

