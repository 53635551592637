import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import { withAuth } from "../lib/AuthProvider";
import { Link } from "react-router-dom";

class SignupForm extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            validated: false, 
            username: "",
            password: ""
        };
    };

    handleSubmit = event => {
        const form = event.currentTarget;
        const { username, password } = this.state;
       
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            this.props.signup({ username, password });
        }
        this.setState({ validated: true });
    }
    

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
       
    };

    render() {
        const { username, password, validated } = this.state;
        
        return (
          <div className="myContainer-sesion">
            <Form
                noValidate
                validated={validated}
                onSubmit={e => this.handleSubmit(e)}
                className="text-center"
            >
                <h2 className="center-log">Sign up</h2>
                <div className="sign_profile">
                    <img src="../Images/avatar@2x.png" width="74px" height="74px"  alt="avatar"></img>
                    <div className="data">
                        <p>{username}</p>
                    </div>
                </div>
                <Form.Group controlId="validationCustom01" className="sign">
                    <Form.Label>User</Form.Label>
                    <Form.Control 
                        className="sign"
                        required
                        type="text"
                        name="username"
                        minlength="4"
                        value={username}
                        onChange={this.handleChange}
                        style={{width: '15%',textAlign: 'center'}}
                    />
                    <div className="line"></div>
                    <Form.Control.Feedback type="invalid">
                       Usuario requerido mínimo 4 carácteres
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Nice Name!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="validationCustom03" className="sign">
                    <Form.Label>Password:</Form.Label>
                    <Form.Control
                        required
                        type="password"
                        name="password"
                        minlength="4"
                        value={password}
                        onChange={this.handleChange}
                        style={{width: '15%',textAlign: 'center'}}              
                    />
                    <div className="line"></div>
                    <Form.Control.Feedback type="invalid">
                        Password requerido mínimo 4 carácteres
                    </Form.Control.Feedback>
                    <Form.Control.Feedback>Well done!</Form.Control.Feedback>
                </Form.Group>  
                <Button variant="primary" type="submit">
                        Submit
                </Button>
                <p>
                Already have account?
                <button><Link to={"/login"}>Login</Link></button>
                </p>
            </Form>
        </div>
        );
    }
}

export default withAuth(SignupForm);
