import React, { Component } from 'react';
import LoadingDots  from "../components/LoadingDots";
import sales from '../lib/sales-service';
import { Button, Form } from 'react-bootstrap';
import { withAuth } from "../lib/AuthProvider";
import Cal from "../components/date";
import CalNew from "../components/dateNew";
import CalOld from "../components/dateOld";
import moment from 'moment';
import $ from 'jquery';
import GaugeChart from 'react-gauge-chart'

class Dashboard extends Component {
    state = {
        data: {
            data: {
                id:'',
                name: '',
                start: '',
            },
        },
        kpi: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        // kpiold: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        
        kpiold: [{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},
        {},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},],
        
        kpiold0: [{CODIGO:'',DESCRIPCION:'',FECHA: 'sin datos',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},
        {CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},{CODIGO:'',DESCRIPCION:'',FECHA: '',TIPO:'', HORA9: '', HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        history: [{CODIGO:'',DESCRIPCION:'',FECHA:'',TIPO:'', HORA9: '', HORA9: '',HORA10:'', HORA11:'', HORA12:'', HORA13: '',HORA14:'', HORA15:'', HORA16:'', HORA17: '',HORA18:'', HORA19:'', HORA20:'', HORA21: '', HORA22:'', HORA23:''},],
        id: [{_id: 0},{_id: 1},{_id: 2},{_id: 3},{_id: 4},{_id: 5},{_id: 6},{_id: 7},{_id: 8},{_id: 9},{_id: 10},{_id: 11}],
        hour: '',
        isLoading: true,
        status: "loading",
        timer: '',
        autoplay: false,
        realtime: '',
        calendarDate: true,
    }

    componentDidMount() {
        setTimeout(() => {
            let hora = moment().format('HH')
            let min = moment().format('mm')
            this.handledbComplete()
         
        }, 1000)
       
        this.setState({
            timer: setInterval(() => {
                // let hora = moment().format('HH')
                let min = moment().format('mm:ss')
                switch (min) {
                    case '01:00' :
                      console.log('00!');
                      // this.handleSales()
                        this.handledbComplete()
                        // this.setState({
                        // hora,
                        // min
                        // })
                      break;
                    case '11:00':
                      console.log('10!');
                      // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '21:00':
                      console.log('20!');
                      // this.handleSales()
                     this.handledbComplete()
                    //  this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '31:00':
                      console.log('30!');
                      // this.handleSales()
                     this.handledbComplete()
                    //  this.setState({
                    //     hora,
                    //     min
                    //     })
                      break;
                    case '41:00':
                    console.log('40!');
                    // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    // hora,
                    // min
                    // })
                    break;
                    case '51:00':
                    console.log('50!');
                    // this.handleSales()
                    this.handledbComplete()
                    // this.setState({
                    // hora,
                    // min
                    // })
                    break;
                    default:
                    
                }
            }, 1000)
        })
    }
      
    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }

    convertArray = (kpi) => {
        kpi.sort((a,b) => {
            if (a.TIPO < b.TIPO){
                return -1;
            } return 1;
        })
       
        this.arraymove(kpi,0,75) //COMP
        // this.arraymove(kpi,76,0) //D001
        // this.arraymove(kpi,77,1) //D101
        this.arraymove(kpi,76,84) //E-COMMERCE
        this.arraymove(kpi,79,83) //I101
        this.arraymove(kpi,78,82) //I100
        this.arraymove(kpi,77,83) //I010
        this.arraymove(kpi,76,82) //I000

        let ind = kpi.map((e,i) => e.CODIGO === 'T210' ?  i: '').filter(String)
        
        this.arraymove(kpi,ind,62) //T210
        // this.arraymove(kpi,78,79) //NO COMP

        // this.arraymove(kpi,0,75) //COMP
        // this.arraymove(kpi,73,0) //D001
        // this.arraymove(kpi,74,1) //D101
        // this.arraymove(kpi,76,86) //E-COMMERCE
        // this.arraymove(kpi,79,84) //I101
        // this.arraymove(kpi,78,83) //I100
        // this.arraymove(kpi,77,82) //I010
        // this.arraymove(kpi,76,81) //I000
        // arraymove(kpi,74,76) //NO COMP
    }
   
    filterShop = (kpi) => { //ELIMINAR TIENDAS

    let indexes = kpi.map((e,i) =>  e.CODIGO === 'T005' 
                                || e.CODIGO === 'T027'
                                || e.CODIGO === 'I302'
                                || e.CODIGO === 'I001'
                                || e.CODIGO === 'T035'
                                || e.CODIGO === 'T041'
                                || e.CODIGO === 'I032'
                                || e.CODIGO === 'T999'
                                || e.CODIGO === 'F006'
                                || e.CODIGO === 'F004'
                                || e.CODIGO === 'F001' 
                                || e.CODIGO === 'T609' 
                                || e.CODIGO === 'T301'  ?  i: '').filter(String)
                            
    for(let i = indexes.length -1; i >= 0; i--){
        kpi.splice(indexes[i],1)
    }

    let indexes2  = kpi.map((e,i) =>  e.CODIGO === 'T409'
                                || e.CODIGO === 'T029'
                                || e.CODIGO === 'D001'
                                || e.CODIGO === 'D101'
                                || e.CODIGO === null ? i: '').filter(String)

    for(let i = indexes2.length -1; i >= 0; i--){
        kpi.splice(indexes2[i],1)
        }

    }

    unionEco = (kpi) => { //UNIR ECORGANIC
      
       let date = kpi[0].FECHA
    if (date > '2022-10-03') {
        console.log('mayor '+ kpi.length)
        // let i = kpi.map((e,i) => e.CODIGO === 'T401' ?  i: '').filter(String)
        // kpi[i].CODIGO = 'T601'
        // console.log(kpi[i])
        let oldIndexes = kpi.map((e,i) => e.CODIGO === 'T401' || e.CODIGO === 'T402' || e.CODIGO === 'T403' || e.CODIGO === 'T404' || e.CODIGO === 'T405'|| e.CODIGO === 'T406' || e.CODIGO === 'T407' || e.CODIGO === 'T408' || e.CODIGO === 'T409'|| e.CODIGO === 'T410' || e.CODIGO === 'T411' || e.CODIGO === 'T412'?  i: '').filter(String)
        for(let i = oldIndexes.length -1; i >= 0; i--){
            kpi.splice(oldIndexes[i],1)
        }
       } else {
        console.log('menor ' + kpi.length)

        let oldIndexes = kpi.map((e,i) => e.CODIGO === 'T601' || e.CODIGO === 'T602' || e.CODIGO === 'T603' || e.CODIGO === 'T604' || e.CODIGO === 'T605' || e.CODIGO === 'T606' ?  i: '').filter(String)
        for(let i = oldIndexes.length -1; i >= 0; i--){
            kpi.splice(oldIndexes[i],1)
        }
        let i = kpi.map((e,i) => e.CODIGO === 'T401' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T601'
         i = kpi.map((e,i) => e.CODIGO === 'T402' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T602'
         i = kpi.map((e,i) => e.CODIGO === 'T403' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T603'
         i = kpi.map((e,i) => e.CODIGO === 'T404' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T604'
         i = kpi.map((e,i) => e.CODIGO === 'T405' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T605'
         i = kpi.map((e,i) => e.CODIGO === 'T406' ?  i: '').filter(String)
        kpi[i].CODIGO = 'T606'

        let oldIndexes2 = kpi.map((e,i) =>  e.CODIGO === 'T607' || e.CODIGO === 'T210'|| e.CODIGO === 'T609'|| e.CODIGO === 'T610' || e.CODIGO === 'T611' || e.CODIGO === 'T612' ?  i: '').filter(String)
        for(let o = oldIndexes2.length -1; o >= 0; o--){
            kpi.splice(oldIndexes2[o],1)
        }
        
        
        let o = kpi.map((e,i) => e.CODIGO === 'T407' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T607'

         o = kpi.map((e,i) => e.CODIGO === 'T408' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T210'

        // o = kpi.map((e,i) => e.CODIGO === 'T409' ?  i: '').filter(String)
        // kpi[o].CODIGO = 'T609'

        o = kpi.map((e,i) => e.CODIGO === 'T410' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T610'

        o = kpi.map((e,i) => e.CODIGO === 'T411' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T611'

        o = kpi.map((e,i) => e.CODIGO === 'T412' ?  i: '').filter(String)
        kpi[o].CODIGO = 'T612'

        }

        

    }
    
    handledbComplete = () => {
        const date =  moment().format('YYYY-MM-DD')
        this.setState({
            isLoading: false
            })
        sales.read (date)
        .then((kpi) => {
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then (() => {
            //convert Date and add to kpi.FECHA
            let indexes = this.state.kpi.map((e,i) => e.FECHA !== null  ?  i: '').filter(String)
            const FECHA =  moment(this.state.kpi[indexes[0]].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            });
        })
        .then(() => {
            const {kpi} = this.state
            const dateHour = new Date()
            const hour = dateHour.getHours()
            this.setState({
                hour,
                status: "loaded",
                isLoading: true,
                autoplay: true,
            })
            this.handleOldSales(kpi[0].FECHA)
        })
        .then(()=> {
            const {kpi} = this.state
            let hora = moment(kpi[0].updated_at).format("HH")
            let min =  moment(kpi[0].updated_at).format("mm")
            this.setState({
                hora,
                min
            })
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })
    }
    
    handleOldSales = (olDate) => {
        this.setState({
            isLoading: false,
        });
        // let olDate = this.state.kpi[0].FECHA
        sales.onlyreadold(olDate)
        .then((kpiold) => {
            if (kpiold.length > 1) {
                this.setState({
                    kpiold,
                    status: "loaded",
                    isLoading: true,
                })
            } else {
                this.setState({
                    kpiold: this.state.kpiold0,
                    status: "loaded",
                    isLoading: true,
                })
            }
        })
        .then (() => {
            if (this.state.kpiold[0].FECHA !== "sin datos" ) {
                //convert Date and add to kpi.FECHA
                let indexes = this.state.kpiold.map((e,i) => e.FECHA !== null ?  i: '').filter(String)
                const FECHA =  moment(this.state.kpiold[indexes[0]].FECHA).format('YYYY-MM-DD')
                const {kpiold} = this.state;
                for (let i = 0; i < kpiold.length; i++) {
                    kpiold[i] = {
                        ...kpiold[i],
                        FECHA: FECHA
                        }
                }
                this.setState({
                    kpiold,
                    isLoading: true,
                });
            }
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false,
            });
        });
    }

    handledb = () => {
        this.setState({
            isLoading: false
        });
        sales.read ()
        .then((kpi) => {
            this.setState({
                kpi,
                status: "loaded",
                isLoading: true,
            });
        })
        .then (() => {
            //convert Date and add to kpi.FECHA
            const FECHA =  moment(this.state.kpi[0].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            });
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
            });
        });
    };

    tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
      
        if (dateStringCandidateValue === ''){
        } else {
            if (!dateStringCandidateValue) { return null; }
            let mapFormat = format
                    .split("")
                    .reduce(function (a, b, i) { a[b] = i; return a;}, {});
            const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
            const datePart = dateStr2Array.slice(0, 3);
            let datePartFormatted = [
                    +datePart[mapFormat.y],
                    +datePart[mapFormat.m]-1,
                    +datePart[mapFormat.d] ];
            if (dateStr2Array.length > 3) {
                dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
            }
            // test date validity according to given [format]
            const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
            return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
                dateTrial.getMonth() === datePartFormatted[1] &&
                dateTrial.getDate() === datePartFormatted[2]
                    ? dateTrial :
                    null;
        }
    }
 
    logChildValue = (kpi, date) => {
        console.log('datos calendario')
        let hour = ''
        this.setState({
            kpi,
            hour,
            calendarDate: true,
        })
        this.handleOldSales(date)
        const dateHour = new Date()
        if (moment(dateHour).format('YYYY-MM-DD') === this.state.kpi[0].FECHA) {
             const hour = dateHour.getHours()
             this.setState({hour})
        }
    }

    logChildValueOld = (kpiold) => {
        console.log('datos antiguos')
        let hour = ''
        this.setState({
            kpiold,
            hour,
            calendarDate: false,
        })
        console.log('old ' + this.state.kpiold.length)

    }

    logChildValueNew = (kpi) => {
        console.log('datos nuevos')
        let hour = ''
        this.setState({
            kpi,
            hour,
            calendarDate: false,
        })
        const dateHour = new Date()
        if (moment(dateHour).format('YYYY-MM-DD') === this.state.kpi[0].FECHA) {
             const hour = dateHour.getHours()
             this.setState({hour})
        }
    }

    arraymove = (arr, fromIndex, toIndex) => {
        let element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
    }

    sendEmail = () => {
        sales.email(this.state.kpi)
    }
    
    fun = (x) => {
        let ne = this.state.kpi.filter(function(val, i) {
            if (val.CODIGO !== 'COMP' && val.CODIGO !== 'NO COMP' && val.CODIGO !== 'FRANQ') {
                return val.TIPO===x;
                } 
            })
        return ne
    }
   
    toggle = () => {
        console.log('pulsado')
        var lab = $("label");
        var swi = $(".custom-switch");
        if (lab.css("color",'green!important')) {
            lab.css("color",'red!important')
            } else {
                lab.css("color",'green!important')
            }
    }

    formatAsPercentage = (num) => {
        return new Intl.NumberFormat('default', {
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(num / 100);
    }

    filtDash = () => {
        let ind  = this.state.kpi.map((e,i) =>  e.CODIGO === 'COMP'
                                    || e.CODIGO === 'E-COMMERCE'
                                    || e.CODIGO === 'FRANQ.' ? i: '').filter(String)
        return ind
    }
   

    render () {
        const { logout } = this.props;
       console.log(this.filtDash())
        const {hour} = this.state;
       
        const formatter = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        })
       
        const redHour = {
            color:'white',
            background:'orange',
            fontWeight: 'bold'
        }
        const greenHour = {
            color:'white',
            background:'#4CAF50',
            fontWeight: 'bold',
        }
        const redOld = {
            // color:'red',
            background:'#f0f3f5'
        }
        const greenOld = {
            // color:'#4CAF50',
            background:'#f0f3f5'
        }
        const green = {
            color:'#4CAF50'
        }
        const red = {
            color:'red'
        }
        const up = {
            background:'#f4de9c',
            borderColor:'#f3ce81'
        }
        const blue = {
            color:'white',
            background: '#2196F3',
            background: '#c95e2c',
            borderColor: '#2c8cf3',
            fontWeight: 'bold',
        }
        /*Nav scroll */
        $(function() {
            var header = $(".fixNav");
            $(window).scroll(function() {    
                var scroll = $(window).scrollTop();
            
                if (scroll >= 150) {
                    header.removeClass('fixNav').addClass("delNav");
                } else {
                    header.removeClass("delNav").addClass('fixNav');
                }
            });

            
        });
       
       

        return (

        <div className="AppPL">
            <div className='fixNav'>
            <div style={{background: '#c95f2c'}}>
            <button style={{background: '#c95f2c'}} className="logout" onClick={logout}><img src="../Images/18404466911582779196-128.png" alt="log" style={{width: '1rem'}}/></button>
            </div>
            <nav className="NavPL">
                <div className="PL">
                <h1>PANEL DE VENTAS</h1>
                <h3>Fecha de ventas:<p> {this.state.kpi[0].FECHA}</p></h3>
                <h3>Fecha comparativa:<p> {this.state.kpiold[0].FECHA}</p></h3>
                </div>
                <Cal parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} onSubmitChild={this.logChildValue} />
                <div className="cal">
                    <h3>SELECCIONA FECHAS A COMPARAR</h3>
                    <div className="cal2">
                    <CalNew parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} onSubmitChild={this.logChildValueNew} />
                    <CalOld parentTextBoxValue={this.state.parentTextBoxValue} calendarDate={this.state.calendarDate} date={this.state.kpiold[0].FECHA} onSubmitChild={this.logChildValueOld} />
                    </div>
                </div>
            </nav>
            </div>
        <header className="App-header-pl dash">
          
            {/* TEST CHAR */}
                
            {/* COMP */}
            {this.state.kpi.map ((kpi, idx) => {
                return <>
                { this.state.hour === 8 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA9/this.state.kpiold[idx].HORA9)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA9)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA9)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 9 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA10/this.state.kpiold[idx].HORA10)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA10)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA10)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 10 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA11/this.state.kpiold[idx].HORA11)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA11)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA11)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 11 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA12/this.state.kpiold[idx].HORA12)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA12)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA12)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 12 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA13/this.state.kpiold[idx].HORA13)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA13)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA13)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 13 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA14/this.state.kpiold[idx].HORA14)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA14)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA14)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 14 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA15/this.state.kpiold[idx].HORA15)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA15)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA15)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 15 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA16/this.state.kpiold[idx].HORA16)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA16)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA16)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 16 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA17/this.state.kpiold[idx].HORA17)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA17)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA17)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 17 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA18/this.state.kpiold[idx].HORA18)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA18)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA18)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 18 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA19/this.state.kpiold[idx].HORA19)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA19)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA19)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 19 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA20/this.state.kpiold[idx].HORA20)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA20)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA20)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 20 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA21/this.state.kpiold[idx].HORA21)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA21)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA21)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 21 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA22/this.state.kpiold[idx].HORA22)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA22)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA22)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 22 && kpi.CODIGO === 'COMP' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA23/this.state.kpiold[idx].HORA23)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>COMPARABLE</h5>
                        <h5>{formatter.format(kpi.HORA23)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA23)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                </>
                })
            }
             {/* ONLINE */}
            {this.state.kpi.map ((kpi, idx) => {
                return <>
                { this.state.hour === 8 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA9/this.state.kpiold[idx].HORA9)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA9)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA9)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 9 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA10/this.state.kpiold[idx].HORA10)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA10)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA10)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 10 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA11/this.state.kpiold[idx].HORA11)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA11)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA11)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 11 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA12/this.state.kpiold[idx].HORA12)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA12)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA12)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 12 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA13/this.state.kpiold[idx].HORA13)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA13)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA13)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 13 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA14/this.state.kpiold[idx].HORA14)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA14)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA14)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 14 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA15/this.state.kpiold[idx].HORA15)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA15)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA15)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 15 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA16/this.state.kpiold[idx].HORA16)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA16)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA16)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 16 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA17/this.state.kpiold[idx].HORA17)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA17)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA17)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 17 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA18/this.state.kpiold[idx].HORA18)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA18)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA18)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 18 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA19/this.state.kpiold[idx].HORA19)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA19)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA19)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 19 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA20/this.state.kpiold[idx].HORA20)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA20)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA20)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 20 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA21/this.state.kpiold[idx].HORA21)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA21)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA21)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 21 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA22/this.state.kpiold[idx].HORA22)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA22)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA22)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 22 && kpi.CODIGO === 'E-COMMERCE' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA23/this.state.kpiold[idx].HORA23)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>ONLINE</h5>
                        <h5>{formatter.format(kpi.HORA23)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA23)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                </>
                })
            }
            {/* FRANQ. */}
            {this.state.kpi.map ((kpi, idx) => {
                return <>
                { this.state.hour === 8 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA9/this.state.kpiold[idx].HORA9)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA9)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA9)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 9 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA10/this.state.kpiold[idx].HORA10)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA10)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA10)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 10 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA11/this.state.kpiold[idx].HORA11)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA11)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA11)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 11 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA12/this.state.kpiold[idx].HORA12)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA12)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA12)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 12 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA13/this.state.kpiold[idx].HORA13)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA13)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA13)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 13 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA14/this.state.kpiold[idx].HORA14)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA14)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA14)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 14 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA15/this.state.kpiold[idx].HORA15)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA15)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA15)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 15 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA16/this.state.kpiold[idx].HORA16)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA16)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA16)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 16 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA17/this.state.kpiold[idx].HORA17)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA17)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA17)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 17 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA18/this.state.kpiold[idx].HORA18)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA18)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA18)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 18 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA19/this.state.kpiold[idx].HORA19)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA19)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA19)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 19 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA20/this.state.kpiold[idx].HORA20)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA20)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA20)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 20 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA21/this.state.kpiold[idx].HORA21)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA21)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA21)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 21 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA22/this.state.kpiold[idx].HORA22)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA22)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA22)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                    { this.state.hour === 22 && kpi.CODIGO === 'FRANQ.' ?
                    <div key={kpi.CODIGO}>
                        <GaugeChart 
                            id="gauge-chart2" 
                            nrOfLevels={30} 
                            // percent={0.86}
                            percent={(kpi.HORA23/this.state.kpiold[idx].HORA23)}
                            arcWidth={0.15}
                            arcPadding={0.01}
                            needleColor={"#5f2412"}
                            needleBaseColor={"#c95f2c"}
                            colors={["orange","#4CAF50"]}
                            textColor={"black"} 
                        />
                        <h5>FRANQ.</h5>
                        <h5>{formatter.format(kpi.HORA23)}</h5>
                        <h5>{formatter.format(this.state.kpiold[idx].HORA23)}</h5>
                        </div>
                    :
                    <>
                    </>
                    }
                </>
                })
            }
                
            
            {/* TEST CHART */}
            {this.state.isLoading === false ?
             <LoadingDots/>               
            :
            <div>
                {/* <Button variant="info" className='butn' onClick={() => this.handleSales()}>READ FROM CMZ4</Button>
                <Button variant="primary" className='butn' onClick={() => this.handledb()}>READ FROM MONGO</Button>
                <Button variant="secondary" className='butn' onClick={() => this.handleOldSales()}>READ OLD MONGO</Button>
                <Button variant="secondary" className='butn' onClick={() => this.handleSalesYear()}>Update DB</Button>
                <Button variant="secondary" className='butn' onClick={() => this.sendEmail()}>SendEmail</Button> */}
            </div>
            }
        </header>
        </div>
        )
    }
}

export default withAuth(Dashboard);