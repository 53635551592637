import React, { Component } from "react";
import { withAuth } from "../lib/AuthProvider";
import logo from '../logo.svg';

class Navbar extends Component {
  
  render() {
    const { logout, isLoggedin } = this.props;
    return (
      <div >
        {isLoggedin ? (
          <>
          <nav className="Nav">
            <h1>KPI "LA FÓRMULA DEL ÉXITO"</h1>
            <div>
              <img src={logo} className="App-logo" alt="logo" />
              <button className="logout" onClick={logout}><img src="../Images/18404466911582779196-128.png" alt="log" style={{width: '2.5rem'}}/></button>
            </div>
          </nav>
          </>
        ) : (
          <>
          </>
        )}
      </div>
    );
  }
}

export default withAuth(Navbar);