import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import LoadingDots  from "../components/LoadingDots";
import sales from '../lib/sales-service';
import sftp from '../lib/sftp-service';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import es from 'date-fns/locale/es';

registerLocale('es', es)


class Cal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date:'',
            isLoading: true,
            kpi: '',
            calendarDate: '',
            ppto: [{CENTRO: '',COSTE: '', DAY: '', FECHA:'', MARGEN: '', MONTH: '', VENTAS:'', YEAR:''},],
        }
    }
    submitForm = () => {
        setTimeout(() => {
            this.props.onSubmitChild(this.state.kpi, this.state.kpi[0].FECHA, this.state.ppto);
        }, 500)
    }

    //Conversor String to Date
    tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
      
        if (!dateStringCandidateValue) { return null; }
        let mapFormat = format
                .split("")
                .reduce(function (a, b, i) { a[b] = i; return a;}, {});
        const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
        const datePart = dateStr2Array.slice(0, 3);
        let datePartFormatted = [
                +datePart[mapFormat.y],
                +datePart[mapFormat.m]-1,
                +datePart[mapFormat.d] ];
        if (dateStr2Array.length > 3) {
            dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
        }
        // test date validity according to given [format]
        const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
        return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
            dateTrial.getMonth() === datePartFormatted[1] &&
            dateTrial.getDate() === datePartFormatted[2]
                ? dateTrial :
                null;
    }

    //Guardar fecha seleccionada en calendario y extraer datos del día
    handleCalendarClose = (date) => {
        this.setState({
            date,
            isLoading: false
            })
        sales.read (date) 
        .then((kpi) => {
            this.setState({
                kpi,
                isLoading: true,
            })
            sftp.readmongo (date) 
            .then((ppto) => {
                this.setState({
                    ppto,
                    isLoading: true,
                    autoplay: true,
                })
            })
            .catch(error => {
                this.setState({
                    status: "error",
                    isLoading: false
                })
            })
        })
        .then (() => {
            //convert Date and add to kpi.FECHA
            const FECHA =  moment(this.state.kpi[0].FECHA).format('YYYY-MM-DD')
            const {kpi} = this.state;
            for (let i = 0; i < kpi.length; i++) {
                kpi[i] = {
                    ...kpi[i],
                    FECHA: FECHA
                    }
            };
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
                calendarDate: true,
            })
            // this.submitForm()
        })
        .then(() => { //Establecer fecha recuperada de la base de datos
            const Fecha =  moment(this.state.kpi[0].FECHA).format('DD-MM-YYYY')
            const date = this.tryParseDateFromString(Fecha,'dmy')
            this.setState({
                date,
            })
           this.submitForm()
        })
        .catch(error => {
            this.setState({
                status: 'error',
                isLoading: false
            })
        })

        
       

        }
    
    handleCalendarOpen = (date) => {
        this.setState({
            date,
            })    
    }
    
    componentDidUpdate(prevProps) {
        // Uso tipico (no olvides de comparar las props):
        if (this.props.props !== prevProps.props) {
            this.setState({
                date: this.props.props,
                
            })
        } 
    }
    
    //Calendarios conectados
    static getDerivedStateFromProps(props, state) {
      if (props.calendarDate !== true ) {
            state.date = ''
        }
        return null
    }

    render() {
    const {date} = this.state

    const style = {
        margin: '0 1%',
        padding: '1%',
        border: '1px solid #5F2412',
        borderRadius: '15px',
        width: '25%',
        height: '115px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    }
   
    return (
        <div style={style} className='cal3'>
            <h3>CALENDARIO VERITAS</h3>
        {this.state.isLoading === false ?
           <LoadingDots color={'white'}/>               
           :
           <div className='calMov'>
           <h2>SELECCIONA UNA FECHA</h2>
           <DatePicker
                className="calendar"
                locale='es'
                dateFormat="dd/MM/yyyy"
                minDate={new Date("2023/01/01")}
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                selected={date} //fecha a mostrar
                // selected={new Date ()} //fecha a mostrar
                onChange={date =>  this.setState({
                    date,
                    })}
                onClick={date =>  this.setState({
                    date,
                    })}
                maxDate={new Date()} // Max fecha a escojer
                // onCalendarClose={() => this.handleCalendarClose(date)}
                onSelect={(date) => this.handleCalendarClose(date)}
                onCalendarOpen={() => this.handleCalendarOpen(date)}
                placeholderText="dd/mm/aaaa"
               
            />
            </div>
           }
        </div>
        );
    };
}
export default Cal;