import React, { Component } from 'react';
import LoadingDots  from "../components/LoadingDots";
import sftp from '../lib/sftp-service';
import user from '../lib/user-service';
// import { Button } from 'react-bootstrap';
// import { withAuth } from "../lib/AuthProvider";
import Slider from "react-slick";


class Player extends Component {
    state = {
        data: {
            data: {
                id:'',
                name: '',
                start: '',
            },
        },
        kpi: [{Objetivo:'',Real:'',Diferencia:'',Fecha:'', KPI: '', ObjetivoAcu:'', RealAcu:'', DiferenciaAcu:'', _id: 0},{_id: 1},{_id: 2},{_id: 3},{_id: 4},{_id: 5},{_id: 6},{_id: 7},{_id: 8},{_id: 9},{_id: 10},{_id: 11}],
        // kpi: [{Objetivo:'',Real:'',Diferencia:'',Fecha:'', KPI: '', ObjetivoAcu:'', RealAcu:'', DiferenciaAcu:''},'','','','','','','','','','','','',''],
        id: [{_id: 0},{_id: 1},{_id: 2},{_id: 3},{_id: 4},{_id: 5},{_id: 6},{_id: 7},{_id: 8},{_id: 9},{_id: 10},{_id: 11}],
        isLoading: true,
        status: "loading",
        timer: '',
        autoplay: false,
    }

    componentDidMount() {
        setTimeout(() => {
            this.handlesftp()
            }, 1000)
        
        this.setState({
            timer: setInterval(() => {
                this.handlesftp()
            //}, 30000)
            }, 1800000)
        })
    }
      
    componentWillUnmount() {
        clearTimeout(this.state.timer);
    }
   
    handlesftp = () => {
        console.log('update')
        this.setState({
            isLoading: false,
        });
        sftp.read ()
        .then((kpi) => {
            this.setState({
                kpi,
                isLoading: true,
                autoplay: true,
            })
        })
        .then(() => {
            const kpi = this.state.kpi
            user.create (kpi)
            this.setState({
                kpi,
                status: "loaded",
                isLoading: true,
                autoplay: true,
            });
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
            });
        });
    };

    handledb = () => {
        this.setState({
            isLoading: false
        });
        user.read ()
        .then((kpi) => {
            this.setState({
                kpi,
                status: "loaded",
                isLoading: true,
            });
        })
        .catch(error => {
            this.setState({
                status: "error",
                isLoading: false
            });
        });
    };

    tryParseDateFromString = (dateStringCandidateValue, format = "ymd") => {
      
        if (dateStringCandidateValue === ''){
        } else {
            if (!dateStringCandidateValue) { return null; }
            let mapFormat = format
                    .split("")
                    .reduce(function (a, b, i) { a[b] = i; return a;}, {});
            const dateStr2Array = dateStringCandidateValue.split(/[ :\-\/]/g);
            const datePart = dateStr2Array.slice(0, 3);
            let datePartFormatted = [
                    +datePart[mapFormat.y],
                    +datePart[mapFormat.m]-1,
                    +datePart[mapFormat.d] ];
            if (dateStr2Array.length > 3) {
                dateStr2Array.slice(3).forEach(t => datePartFormatted.push(+t));
            }
            // test date validity according to given [format]
            const dateTrial = new Date(Date.UTC.apply(null, datePartFormatted));
            return dateTrial && dateTrial.getFullYear() === datePartFormatted[0] &&
                dateTrial.getMonth() === datePartFormatted[1] &&
                dateTrial.getDate() === datePartFormatted[2]
                    ? dateTrial :
                    null;
        }
    }
 
    logChildValue = (value) => {
        this.setState({kpi: value});
    }
    
    render () {
        const {Fecha} = this.state.kpi[0]
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 2,
            autoplay: true,
            autoplaySpeed: 15000
        };
        
        return (
        <div className="AppPL">
            <nav className="NavPL">
                <h1>SEGUIMIENTO KPIs</h1>
                <div>
                <h1>{this.state.kpi[0].Fecha}</h1>
                </div>
            </nav>
        <header className="App-header-pl">
            <Slider {...settings} style={{margin: '10% 2.5%'}}>
                {this.state.kpi.map( kpi => {
                    return  <div key={kpi._id}>
                                <table style={{width: '100%'}}>
                                    <thead style={{margin: '5% 0 0 0'}}>
                                        <tr className='trplayer'>
                                            <th className="descpl"><h3>{kpi.KPI}</h3></th>
                                            <th className='tdContainer'>
                                                <th><h3>OBJETIVO</h3></th>
                                                <th><h3>REAL</h3></th>
                                                <th><h3>DIFERENCIA</h3></th>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='trplayer' >
                                            <td className="tdprimary">
                                                <p>DIARIO</p>
                                                <br></br>
                                                <p>ACUMULADO MES</p>
                                            </td>
                                            <td className='tdContainerBorder'>
                                                <td>
                                                    <h3 >{kpi.Objetivo}</h3>
                                                    <h3>{kpi.ObjetivoAcu}</h3>
                                                </td>
                                                <td>
                                                    <h3>{kpi.Real}</h3>
                                                    <h3>{kpi.RealAcu}</h3>
                                                </td>
                                                { kpi.Diferencia === undefined ?
                                                <td>
                                                    <h3>{kpi.Diferencia}</h3>
                                                    <h3>{kpi.DiferenciaAcu}</h3>
                                                </td>
                                                :  
                                                <td>
                                                    { !kpi.KPI.includes('ABSENTISM') && !kpi.KPI.includes('COMPONENTES EQUIPO VERITAS')?
                                                    <>
                                                        <h3 style={kpi.Diferencia.includes('-') === true ? {color:'red'} : {color:'green'}}>{kpi.Diferencia}</h3>
                                                        <h3 style={kpi.DiferenciaAcu.includes('-') === true ? {color:'red'} : {color:'green'}}>{kpi.DiferenciaAcu}</h3>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 style={kpi.Diferencia.includes('-') === true ? {color:'green'} : {color:'red'}}>{kpi.Diferencia}</h3>
                                                        <h3 style={kpi.Diferencia.includes('-') === true ? {color:'green'} : {color:'red'}}>{kpi.Diferencia}</h3>
                                                    </>
                                                    }
                                                </td>
                                                
                                                // <td>
                                                //     <h3 style={kpi.Diferencia.includes('-') === true ? {color:'red'} : {color:'green'}}>{kpi.Diferencia}</h3>
                                                //     <br></br>
                                                //     <h3 style={kpi.DiferenciaAcu.includes('-') === true ? {color:'red'} : {color:'green'}}>{kpi.DiferenciaAcu}</h3>
                                                // </td> 
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> 
                    })
                }
            </Slider>    
            {this.state.isLoading === false ?
             <LoadingDots/>               
            :
            <div>
                {/* <Button variant="info" className='butn' onClick={() => this.handlesftp()}>READ FROM SFTP</Button> */}
                {/* <Button variant="primary" className='butn' onClick={() => this.handledb()}>READ FROM DB</Button> */}
            </div>
            }
        </header>
        </div>
        )
    };
}

export default Player;