import React, { Component } from "react";
import auth from "./auth-service";
import LoadingDots  from "../components/LoadingDots";

const { Consumer, Provider } = React.createContext();

export { Consumer };

export const withAuth = Comp => {
  return class WithAuth extends Component {
    render() {
      return (
        <Consumer>
          {authStore => {
            return (
              <Comp
                login={authStore.login}
                signup={authStore.signup}
                user={authStore.user}
                logout={authStore.logout}
                isLoggedin={authStore.isLoggedin}
                retry={authStore.retry}
                {...this.props}
              />
            );
          }}
        </Consumer>
      );
    }
  };
};

class AuthProvider extends Component {
  state = {
    isLoggedin: false,
    user: null,
    isLoading: true,
    retry: true
  };

  componentDidMount() {
    auth
      .me()
      .then(user => {
        this.setState({
          isLoggedin: true,
          user,
          isLoading: false
        });
      })
      .catch(() => {
        this.setState({
          isLoggedin: false,
          user: null,
          isLoading: false
        });
      });
  };

  signup = user => {
    const { username, password } = user;
    auth.signup({ username, password })
      .then(user => {
        this.setState({
          isLoggedin: true,
          user
        });
      })
      .catch(({ response: { data: error } }) => {
        this.setState({
          message: error.statusMessage
        });
      });
  };

  login = user => {
    const { username, password } = user;
    auth.login({ username, password })
      .then(user => {
        this.setState({
          isLoggedin: true,
          retry: false,
          user
        });
      })
      .catch((error) => {
        this.setState({
          retry: true,
        });
        // console.log(error)
      });
  };

  logout = () => {
    auth
      .logout()
      .then((res) => {
        this.setState({
          isLoggedin: false,
          user: null
        });
      })
      .catch(() => {});
  };

  render() {
    const { isLoading, retry, isLoggedin, user } = this.state;

    return isLoading ? (
      <LoadingDots/> 
      ) : (
      <Provider
        value={{
          isLoggedin,
          retry,
          user,
          login: this.login,
          logout: this.logout,
          signup: this.signup
        }}
      >
      {this.props.children}
      </Provider>
    );
  }
}

export default AuthProvider;